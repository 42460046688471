import { getDate } from 'helpers/date';
import { MakeOfferValues } from './EditOfferModal.types';

export const getMinDate = (comparasionDate?: string | Date) => {
  const availableOnDate = getDate(comparasionDate);
  const todayDate = getDate();
  return availableOnDate > todayDate ? availableOnDate : todayDate;
};

export const parseDefaultValues = (initialValues?: MakeOfferValues) => {
  return {
    date: getMinDate(initialValues?.date),
    proposedRent: initialValues?.proposedRent || '',
    totalOccupants: initialValues?.adultOccupants || '',
    adultOccupants: initialValues?.adultOccupants || '',
    childrenOccupants: initialValues?.childrenOccupants || '',
    note: initialValues?.note || '',
  };
};

export const defaultValues = {
  date: new Date(),
  proposedRent: '',
  totalOccupants: '',
  adultOccupants: '',
  childrenOccupants: '',
  note: '',
};
