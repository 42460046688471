import { useState, useEffect } from 'react';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import { Button } from 'legacy-components/componets';
import dayjs from 'dayjs';
import { ConsentAcknowledgment } from './types';
import { ConsentAcknowledgmentType } from 'common/enums/enums';

interface Props {
    isOpen: boolean;
    onAccept: (value: ConsentAcknowledgment) => void;
    onClose: () => void;
    acknowledgment: ConsentAcknowledgment;
    dialogContent: JSX.Element;
}

/** DialogTitle child component */
const DialogTitle = ({ text, component }) => {
    return (
        <div className="flex flex-col mt-2 px-4">           
            <div className="grow h-6 pr-4 relative">
                {component}
            </div>
            <div className="p-1 text-center">
                <h2 className="lg:text-[24px] md:text-[18px] text-lg font-bold">{text}</h2>
            </div>
        </div>
    );
}

/** SignsArea child component */
const SignsArea = ({ children }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 mt-5 pt-5">
            {children}
        </div>
    );
}

/** The main ConsentAcknowledgment component */
function ConsentAcknowledgmentModal({ isOpen, onAccept, onClose, acknowledgment, dialogContent }: Props) {
    const [state, setState] = useState<ConsentAcknowledgment>({
        applicantFirstname: '',
        applicantLastname: '',
        applicantSignature: '',
        signedAt: '',
        isSigned: false,
        acknowledgmentType: ConsentAcknowledgmentType.PERSONAL_INFO
    });

    const isoDateFormat = 'YYYY-MM-DD';
    
    const staticTexts = { 
        title: "Consent & Acknowledgment",
        applicationFee: "Application fee",
        applicantSignature: "Applicant's signature"
    };

    const handleApplicantSignatureChanged = (value: string) => {
        setState({...state, applicantSignature: value});
    }
    
    const handleSignedAtChanged = (value) => {
        setState({...state, signedAt: value});
    }   
       
    useEffect(() => {
        if(!acknowledgment?.signedAt) {
            acknowledgment.signedAt = dayjs(new Date())
                .format(isoDateFormat);
        } else {
            acknowledgment.signedAt = dayjs(acknowledgment.signedAt, 
                ['YYYY-MM-DD', 'YYYY-MM-DDThh:mm:ss']
            ).format(isoDateFormat);
        }

        setState({...acknowledgment});        
    }, [isOpen]);

    return (     
        <Dialog maxWidth="md" open={isOpen} onClose={onClose}>
            <DialogTitle text={staticTexts.title} 
                component={
                    <Tooltip title="Close">
                        <button className="absolute top-0 right-0" onClick={onClose}  >
                            <CloseOutlined color="primary" />
                        </button>
                    </Tooltip>
                }
             />
             <div className="md:text-[14px] sm:text-[12px] indent-2 bg-sky-50 rounded-md mx-4 my-2 py-1">
                {dialogContent}
             </div>

            <SignsArea>                
                <div className="col-span-1 md:col-span-2 text-[15px]">
                    <div className="flex text-[14px] mx-2 gap-x-2 px-[24px] relative">                    
                        <label className="font-bold text-trueGray">{staticTexts.applicantSignature}:</label>                 
                        <input 
                            name="applicantSignature" 
                            type="text"
                            className="w-[176px] text-4 border-b border-gray-200 focus:outline-none"
                            value={state.applicantSignature ?? ''}
                            onChange={(e) => handleApplicantSignatureChanged(e.target?.value)}
                        />
                        {/*<span className="text-sm text-trueGray"><sub>{`${state.applicantFirstname} ${state.applicantLastname}`}</sub></span>*/}
             
                        <div className="w-[176px] text-center absolute bottom-[-14px] left-[190px] italic text-sm text-trueGray">
                            <sub>signature</sub>
                        </div>
                    </div>
                </div>    
                <div> 
                    <div className="flex text-[14px] mx-2 gap-x-2 pl-[26px]">
                        <label className="font-bold text-trueGray">Signed at:</label>
                        <input 
                            name="signedAt" 
                            type="date"
                            min={dayjs(new Date()).subtract(1, 'week').format(isoDateFormat)}
                            max={dayjs(new Date()).format(isoDateFormat)}
                            value={state.signedAt ?? ''}                       
                            className="w-[135px] border-b border-gray-200 focus:outline-none"
                            onChange={(e) => handleSignedAtChanged(e.target.value)}
                        />                        
                    </div>
                </div>
            </SignsArea>
            <div className="flex mt-12 p-4 justify-center gap-3">                 
                <Button label="Accept" theme="primary" 
                    disabled={!state.applicantSignature}
                    onClick={(e) => onAccept({...state})} 
                />               
            </div>
        </Dialog>
    );
}

export default ConsentAcknowledgmentModal;