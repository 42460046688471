export const joinValues = (values: any[], separator: string): string => {
  return values.filter(Boolean).join(separator);
};

export const formatToLocaleString = (value: number, locale = 'en-US') => {
  return value ? value.toLocaleString(locale) : '0';
};

export const formatPriceValue = (value: number): string => {
  const isNegative = value < 0;

  const integerValue = Math.floor(Math.abs(value));
  const decimalValue = Math.abs(value) - integerValue;

  const formattedInteger = integerValue.toLocaleString('en-US');

  const formattedDecimal = decimalValue.toFixed(2).slice(1);

  const formattedValue = `${isNegative ? '-' : ''}${formattedInteger}${decimalValue ? formattedDecimal : ''}`;
    
  return value ? formattedValue : '0';
};

export const formatWordsToPascalCaseWithSpaces = (value: string): string => {
  return value
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const splitPascalCaseWords = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
};
