import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { NotificationType } from 'common/enums/services/notification/notification-type.enum';
import { NotificationModalState } from 'common/types/components/common/notification-modal.type';

type Store = NotificationModalState;

const useNotificationModalStore = create<Store>()(
  immer((set) => ({
    type: NotificationType.INFO,
    title: '',
    open: false,
    description: '',
    buttonTitle: 'Got it',
    onClose: () => {},
  })),
);

export const openNotificationModal = (stateToOpen: Omit<NotificationModalState, 'open'>) => {
  useNotificationModalStore.setState((state) => {
    state.open = true;
    state.description = stateToOpen.description;
    state.type = stateToOpen.type;
    state.title = stateToOpen.title;
    state.buttonTitle = stateToOpen.buttonTitle ?? 'Got it';
    if (stateToOpen.onClose) {
      state.onClose = stateToOpen.onClose;
    }
  });
};

export const closeNotificationModal = () => {
  useNotificationModalStore.setState((state) => {
    if (state.onClose) {
      state.onClose();
    }
    state.open = false;
    state.onClose = () => {};
  });
};

export default useNotificationModalStore;
