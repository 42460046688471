import DialogContent from "@mui/material/DialogContent";

const ProfileAcknowledgmentDialogContent = () => {
  return (
    <DialogContent>
      <p>I hereby certify that I am at least 18 years of age and that all information given on this application is 
          true and correct.</p>
      <p>I authorize Axxelist/Landlord and its agents to obtain an investigative consumer 
          credit report including, but not limited to, credit history, OFAC search, landlord/tenant court record 
          search, criminal record search and registered sex offender search. I authorize the release of 
          information from previous or current landlords, employers, bank representatives, and personal 
          references.</p>
      <p>I agree to furnish additional credit and/or personal references upon request. I understand 
          incomplete or incorrect information provided in this application may cause a delay in processing 
          which may result in denial of tenancy. This investigation is for resident screening purposes only and is 
          strictly confidential. I hereby hold Axxelist/Landlord and its agents free and harmless of any liability for 
          any damages arising out of any improper use of this information.</p>
      <p>Important information about your rights under the Fair Credit reporting Act:</p>
      <ul className="list-inside list-disc">
          <li>You have a right to request disclosure of the nature and scope of the investigation</li>
          <li>You must be told if information in your file has been used against you.</li>
          <li>You have a right to know what is in your file, and this disclosure may be free</li>
          <li>You have the right to ask for a credit score (there may be a fee for this service)</li>
          <li>You have the right to dispute incomplete or inaccurate information</li>
      </ul>
      <p>Consumer reporting agencies must correct inaccurate, incomplete, or unverifiable information</p>
      <p>Consumer Response Center, Room 130-A, Federal Trade Commission, 600 Pennsylvania Avenue
          N.W., Washington D.C. 20580.</p>
      <p>In connection with my application for housing, I understand that Axxelist, the property owner/agent
          may obtain one or more consumer reports, which may contain public information, for the purposes of
          evaluating my application. These consumer reports will be obtained from one or more of the following
          consumer reporting agencies:</p>  
      <ul className="list-inside list-disc">
          <li>Trans Union, Regional Disclosure Center, 1561 Orangethorpe Ave., Fullerton, CA, 92631, (714)
          738-3800</li>
          <li>Equifax, E.C.I.F., P.O. Box 740241, Atlanta, GA, 30374-0241, (800) 685-1111</li>
          <li>Experian (TRW), Consumer Assistance, P.O. Box 949, Allen, TX, 75002, (888) 397-3742</li>
      </ul>  
      <p>These consumer reports are defined as investigative consumer reports. These reports may contain
          information on my character, general reputation, personal characteristics, and mode of living. In
          connection with my application for housing, I authorize Axxelist, owner/agent to obtain a consumer
          report from the consumer reporting agencies listed above.</p>
    </DialogContent>
  );
}

export default ProfileAcknowledgmentDialogContent