import { EstateShortPropertyInfo } from 'legacy-components/estate/estate-short-property-info/estate-short-property-info';
import { Button } from 'legacy-components/componets';
import { MAKE_OFFER_BTN } from './estate-make-offer-card.constants';
import { useAuth, useHasRoles } from 'hooks';
import { calculateAxxelistFees } from '../utils/estates-utils';
import React, { Fragment, useMemo } from 'react';
import { AuthRole, EstateStatusDto } from 'common/enums/enums';
import { OtherFeeType } from 'common/mappers/property';
// import { checkVerificationErrorMessages } from 'helpers/check-verification-error-messages';
// import { VerificationErrors } from 'legacy-pages/renter/schedule-tour/config';
import { Link } from 'react-router-dom';
import { EstatePriceDetails } from 'components/atoms/EstatePriceDetails/EstatePriceDetails';
import { useUserProfileValidation } from 'hooks/query';
import { RenterIncompleteProfileActionAlert } from 'components/organisms/Alert/RenterIncompleteProfileActionAlert';

export type EstateMakeOfferCardProps = {
  price: number;
  beds: number;
  bathrooms: number;
  propertyArea: number;
  onClick: () => void;
  deposit: number;
  commissionPercent: number;
  status: EstateStatusDto | string;
  otherFee: OtherFeeType[];
  firstMonth: number;
  lastMonth: number;
  fixedCommission: number;
  leaseLength: number;
  offerExists?: boolean | undefined;
  showBrief: boolean;
};

const EstateMakeOfferCard: React.FC<EstateMakeOfferCardProps> = ({
  price,
  beds,
  bathrooms,
  propertyArea,
  onClick,
  deposit,
  commissionPercent,
  status,
  otherFee,
  firstMonth,
  lastMonth,
  fixedCommission,
  leaseLength,
  offerExists,
  showBrief
}) => {
  const { isAuthenticated } = useAuth();
  const { data: userProfileValidation, isFetching } = useUserProfileValidation({ enabled: isAuthenticated });
  // const verificationErrorMessages = userProfileValidation?.errors
  //   ? checkVerificationErrorMessages(userProfileValidation.errors, [
  //       VerificationErrors.UserAddress,
  //       VerificationErrors.IdentityDocument,
  //       VerificationErrors.ProfileImage,
  //       VerificationErrors.PersonalInfo,
  //       VerificationErrors.VerificationDocument,
  //       VerificationErrors.ConfirmEmail,
  //     ])
  //   : [];
  const isValidProfile = userProfileValidation?.isIdentityValid && userProfileValidation.isProfileValid;
  const isLandlord = useHasRoles([AuthRole.Landlord]);
  const isAdmin = useHasRoles([AuthRole.SuperUser]);
  const handleMakeOfferClick = () => {
    if (isAuthenticated) {
      onClick();
    }
  };

  const axxelistFees = useMemo(
    () => (commissionPercent > 0 ? calculateAxxelistFees(price, commissionPercent) : fixedCommission),
    [price, commissionPercent, fixedCommission],
  );

  const estatePriceDetailsProps = useMemo(
    () => ({
      price,
      lastMonth,
      leaseLength,
      firstMonth,
      axxelistFees,
      deposit,
      otherFee,
      showBrief
    }),
    [price, lastMonth, leaseLength, firstMonth, axxelistFees, deposit, otherFee, showBrief],
  );

  return (
    <div className='grid gap-6 p-6 border border-secondary shadow-card rounded-2xl w-full'>
      <EstateShortPropertyInfo beds={beds} bathrooms={bathrooms} propertyArea={propertyArea} />
      <EstatePriceDetails {...estatePriceDetailsProps} />
      {isAuthenticated && !isFetching && !isValidProfile && !isAdmin && !isLandlord && (
        <RenterIncompleteProfileActionAlert />
      )}
      {status === EstateStatusDto.Available && !isAdmin && !isLandlord && (
        <Fragment>
          {offerExists && isAuthenticated ? (
            <Link
              className='bg-white text-primaryDark border border-primary h-10 flex-center text-lg p-6 font-bold rounded-lg focus:outline-none cu'
              to={'/i-rent/profile/offers'}
            >
              {'Offer is sent'}
            </Link>
          ) : (
            <Button
              label={MAKE_OFFER_BTN}
              theme={isAuthenticated ? 'primary' : 'gray'}
              icon={'file-lines'}
              onClick={handleMakeOfferClick}
              disabled={isFetching || !isValidProfile || !isAuthenticated || offerExists === undefined}
            />
          )}
        </Fragment>
      )}
      <p className='font-semibold text-trueGray text-center text-sm'>* Fees not included in monthly rent</p>
      {!isAuthenticated && (
        <p className='font-semibold text-trueGray text-center text-xs'>
          <Link to={'/auth/sign-in'} className='text-primary'>
            Log in
          </Link>{' '}
          or{' '}
          <Link to={'/auth/sign-up'} className='text-primary'>
            Create an account
          </Link>{' '}
          to make an offer
        </p>
      )}
    </div>
  );
};

export { EstateMakeOfferCard };
