import { useFormik } from 'formik';
import { Button, Input, Label, Loader, Textarea } from 'legacy-components/componets';
import { getDate, getDayjs } from 'helpers/date';
import { stringAsFloatNumber, stringAsIntegerNumber } from 'helpers/input-field.helper';
import { MakeOfferModalProps, MakeOfferValues } from './types/types';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { makeOfferFormValidationSchema } from 'validations';
import { CustomDatePicker, MultiSelect } from 'legacy-components/fields/fields';
import { FC, useEffect, useState } from 'react';
import { OfferDocumentsVerificationModal } from '../documents-verification-modal/documents-verification-modal';
import { useWindowSize } from 'hooks';
import { Modal } from 'components/organisms/Modal';
import { useEstateUnitPrice, useCreateOffer } from 'hooks/query';
import { uploadOfferFiles } from 'services/api/offers-api.service';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { useNavigate } from 'react-router-dom';
import { generateDynamicPath } from 'helpers/routes-path';
import { AppRoute } from 'common/enums/enums';
import { UserInfo } from './user-info-block';
import { NextSteps, StepConfig } from './next-steps';
import { SelectOption } from 'common/types/types';
// TODO rewrite component
export const MakeOfferModal: FC<MakeOfferModalProps> = ({
  availableOn,
  unitId,
  onClose,
  open: openProp,
  onSubmit,
  onGoBack,
  withGoBackButton = false,
  address,
  estateId,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [open, setOpen] = useState(true);
  const { data: estateUnitPrice, isLoading: isEstateUnitPriceLoading } = useEstateUnitPrice(
    { estateId, unitId },
    { enabled: openProp },
  );
  const queryClient = useQueryClient();
  const [documentsVerificationModalOpen, setDocumentsVerificationModalOpen] = useState(false);
  const { mutateAsync: createOffer } = useCreateOffer({
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Offers, 'getMyOffers'] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.Estates, estateId, 'extra'] });
    },
  });

  const { values, errors, touched, setFieldValue, submitForm, resetForm } = useFormik<MakeOfferValues>({
    initialValues: {
      date: new Date(),
      proposedRent: '',
      totalOccupants: '',
      adultOccupants: '',
      childrenOccupants: '',
      petsCount: '',
      petsTypes: [],
      petsWeight: '',
      note: '',
      videos: [],
    },
    validationSchema: makeOfferFormValidationSchema(
      estateUnitPrice?.price || 0,
      estateUnitPrice?.firstMonthPrice || 0,
      estateUnitPrice?.lastMonthPrice || 0,
      estateUnitPrice?.deposit || 0,
      estateUnitPrice?.commissionPercent || 0,
      estateUnitPrice?.otherFeeSum || 0,
      estateUnitPrice?.fixedCommission || 0,
    ),
    onSubmit: () => {
      setOpen(false);
      setDocumentsVerificationModalOpen(true);
    },
  });

  const handleDocumentsVerificationModalClose = () => {
    setOpen(true);
    setDocumentsVerificationModalOpen(false);
  };

  const makeOffer = async (bankStatements: File[]): Promise<string> => {
    const offerId = await createOffer({
      unitId: unitId,
      moveInDate: getDayjs(values.date).format('YYYY-MM-DD'),
      proposedRent: Number(values.proposedRent),
      adultOccupants: Number(values.adultOccupants),
      childrenOccupants: Number(values.childrenOccupants),
      description: values.note,
      address: address,
      petsCount: Number(values.petsCount),
      petsTypes: values.petsTypes,
      petsWeight: Number(values.petsWeight),
    });
    await uploadOfferFiles(offerId, bankStatements);
    return offerId;
  };

  const getMinDate = () => {
    const availableOnDate = getDate(availableOn);
    const todayDate = getDate();
    return availableOnDate > todayDate ? availableOnDate : todayDate;
  };

  useEffect(() => {
    if (!availableOn) return;
    const date = getMinDate();
    setFieldValue('date', date);
  }, [availableOn]);

  const minDate = getMinDate();

  const nextStepConfig: StepConfig[] = [{
    id: '10',
    icon: 'pen-with-line',
    description: 'Fill in your proposed offer. Landlord receives this along with your profile'
  }, {
    id: '20',
    icon: 'dollar-solid-circle',
    description: 'A fee of $19.99 per adult applicant is required to process your pre-application'
  }, {
    id: '30',
    icon: 'circle-check',
    description: 'Offer is subject to application and credit checks'
  }, {
    id: '40',
    icon: 'information',
    description: 'Fee is non-refundable'
  }];

  const petTypeOptions: SelectOption[] = [{
    value: 'Cat',
    label: 'Cat'
  }, {
    value: 'Dog',
    label: 'Dog'
  }];

  const notePlaceholder = `e.g. I'm very interested in your property and believe it would be the perfect place for my family. `
  + `My dog is well-behaved and house-trained. `
  + `I'm more than willing to provide references from my previous landlords regarding their good behavior.`;

  const getTotalOccupants = () => {
    return Number(values.adultOccupants) + Number(values.childrenOccupants);
  }

  return (
    <>
      <OfferDocumentsVerificationModal
        onGoBack={() => {
          handleDocumentsVerificationModalClose();
        }}
        onSubmit={async ({ bankStatements }) => {
          const offerId = await makeOffer(bankStatements as File[]);
          handleDocumentsVerificationModalClose();
          resetForm();
          onSubmit?.();
          navigate(`${generateDynamicPath(AppRoute.PRE_OFFER_PAYMENT, { offerId })}`)
        }}
        onClose={() => {
          handleDocumentsVerificationModalClose();
          onClose();
        }}
        open={documentsVerificationModalOpen}
      />
      <Modal
        title={'Make Instant RENT Offer'}
        isOpen={open && openProp}
        withGoBackButton={withGoBackButton}
        isFullScreen={isMobile}
        onGoBack={onGoBack}
        contentClassName={'w-[920px]'}
        onOpenChange={(open) => {
          if (open) return;
          onClose();
        }}
      >
        {isEstateUnitPriceLoading ? (
          <Loader className={'h-[322px]'} />
        ) : (
          <div className='flex flex-col gap-3'>
            {estateUnitPrice?.price && (
              <div className='px-3 py-4 bg-secondary text-center text-base rounded-xl text-trueGray'>
                <span>{'Ensure that proposed rent is not lower than property list price '}</span>
                <span className='font-bold'>${estateUnitPrice?.price}</span>
              </div>
            )}
            <div className='flex sm:flex-row flex-col gap-6'>
              <div className='flex flex-col w-[300px] gap-6'>
                <UserInfo></UserInfo>
                <NextSteps title='What happens next' stepsList={nextStepConfig} />
              </div>
              <div className='grow grid grid-cols-3 gap-4 border border-gray rounded-2xl p-6'>
                <Label label={'Proposed Rent'} className={'col-span-3'}>
                  <ErrorValidationMessage touched={touched.proposedRent} message={errors.proposedRent}>
                    <Input
                      name={'proposedRent'}
                      value={values.proposedRent}
                      onChange={(e) => stringAsIntegerNumber(e, `proposedRent`, setFieldValue)}
                      invalid={Boolean(errors.proposedRent && touched.proposedRent)}
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'When would you like to move in?'} className={'col-span-3'}>
                  <ErrorValidationMessage touched={touched.date} message={errors.date}>
                    <CustomDatePicker
                      minDate={minDate}
                      name='date'
                      value={values.date}
                      onChange={(date) => setFieldValue('date', date)}
                      invalid={Boolean(errors.date && touched.date)}
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Total Occupants'} className={'col-span-1'}>
                  <ErrorValidationMessage touched={touched.totalOccupants} message={errors.totalOccupants}>
                    <Input
                      name={'totalOccupants'}
                      disabled
                      value={getTotalOccupants()}
                      invalid={Boolean(errors.totalOccupants && touched.totalOccupants)}
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Adult (18 yrs old +)'} className={'col-span-1'}>
                  <ErrorValidationMessage touched={touched.adultOccupants} message={errors.adultOccupants}>
                    <Input
                      name={'adultOccupants'}
                      value={values.adultOccupants}
                      onChange={(e) => stringAsIntegerNumber(e, `adultOccupants`, setFieldValue)}
                      onBlur={(e) => setFieldValue('totalOccupants', getTotalOccupants())}
                      invalid={Boolean(errors.adultOccupants && touched.adultOccupants)}
                      placeholder='e.g. 3'
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Children (0-18 yrs old)'} className={'col-span-1'}>
                  <ErrorValidationMessage touched={touched.childrenOccupants} message={errors.childrenOccupants}>
                    <Input
                      name={'childrenOccupants'}
                      value={values.childrenOccupants}
                      onChange={(e) => stringAsIntegerNumber(e, `childrenOccupants`, setFieldValue)}
                      onBlur={(e) => setFieldValue('totalOccupants', getTotalOccupants())}
                      invalid={Boolean(errors.childrenOccupants && touched.childrenOccupants)}
                      placeholder='e.g. 3'
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Pets'} className={'col-span-3 sm:col-span-1'}>
                  <ErrorValidationMessage touched={touched.petsCount} message={errors.petsCount}>
                    <Input
                      name={'petsCount'}
                      value={values.petsCount}
                      onChange={(e) => stringAsIntegerNumber(e, `petsCount`, setFieldValue)}
                      invalid={Boolean(errors.petsCount && touched.petsCount)}
                      placeholder='0'
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Type'} className={'sm:col-span-1 col-span-3'}>
                  <ErrorValidationMessage touched={touched.petsTypes} message={errors.petsTypes}>
                    <MultiSelect
                      name={'petsTypes'}
                      options={petTypeOptions}
                      value={values.petsTypes}
                      onChange={(value) => {
                        setFieldValue('petsTypes', value)
                      }}
                      invalid={Boolean(errors.petsTypes && touched.petsTypes)}
                      placeholder='dog, cat'
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Weight (largest pet)'} className={'sm:col-span-1 col-span-3'}>
                  <ErrorValidationMessage touched={touched.petsWeight} message={errors.petsWeight}>
                    <Input
                      name={'petsWeight'}
                      value={values.petsWeight}
                      onChange={(e) => stringAsFloatNumber(e, `petsWeight`, setFieldValue)}
                      invalid={Boolean(errors.petsWeight && touched.petsWeight)}
                      placeholder='22lbs'
                    />
                  </ErrorValidationMessage>
                </Label>
                <Label label={'Add a personal note to the landlord?'} className={'col-span-3'}>
                  <ErrorValidationMessage touched={touched.note} message={errors.note}>
                    <Textarea
                      name={'note'}
                      value={values.note}
                      onChange={(e) => setFieldValue('note', e.target.value)}
                      invalid={Boolean(errors.note && touched.note)}
                      placeholder={notePlaceholder}
                    />
                  </ErrorValidationMessage>
                </Label>
                <Button label={'Submit'} theme={'primary'} onClick={submitForm} className={'w-full col-span-3'} />
                {
                  Object.keys(errors).length > 0 && Object.keys(touched).length > 0
                    ? <div className='text-[14px] col-span-3'>
                      Please complete all sections to submit an offer
                    </div>
                    : null
                }

              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
