import React from 'react';
import { Button, Modal, NotificationTypeIcon } from 'legacy-components/componets';
import { getNotificationMessageColorClass } from 'helpers/notification';
import { useLocation } from 'react-router-dom';
import useNotificationModalStore, { closeNotificationModal } from 'store/use-notification-modal-store';

const NotificationModal = () => {
  // const location = useLocation();
  const notificationModalStore = useNotificationModalStore();

  // React.useEffect(() => {
  //   return () => {
  //     closeNotificationModal();
  //   };
  // }, [location.pathname]);

  const handleClose = () => {
    closeNotificationModal();
  };

  const messageColor = getNotificationMessageColorClass(notificationModalStore?.type);

  return (
    <Modal
      isOpen={notificationModalStore.open}
      onClose={handleClose}
      styles={{
        overlay: { zIndex: 100 },
        content: {
          maxHeight: '100%',
        },
      }}
    >
      <div className='md:w-[544px] flex flex-col justify-center items-center font-medium p-8 gap-8 text-center'>
        <h4 className={`text-2xl ${messageColor}`}>{notificationModalStore?.title}</h4>
        <NotificationTypeIcon type={notificationModalStore?.type} size={'l'} />
        {notificationModalStore?.description && (
          <span className='text-base text-trueGray'>{notificationModalStore.description}</span>
        )}
        <Button label={notificationModalStore?.buttonTitle ?? 'Got it'} theme={'primary'} onClick={handleClose} isFullWidth />
      </div>
    </Modal>
  );
};

export { NotificationModal };
