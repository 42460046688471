import { useFormik } from 'formik';
import { Button, Input, Label, Loader, Textarea } from 'legacy-components/componets';
import { stringAsIntegerNumber } from 'helpers/input-field.helper';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { makeOfferFormValidationSchema } from 'validations';
import { CustomDatePicker } from 'legacy-components/fields/fields';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'hooks';
import { Modal } from 'components/organisms/Modal';
import { useEstateUnitPrice } from 'hooks/query';
import { formatPriceValue } from 'helpers/string.helpers';
import { MakeOfferModalProps, MakeOfferValues } from './EditOfferModal.types';
import { defaultValues, getMinDate, parseDefaultValues } from './EditOfferModal.utils';
import { OfferDocumentsVerificationModal } from 'legacy-components/modals/documents-verification-modal/documents-verification-modal';
import { BTN_SUBMIT, DATE_FIELD, MODAL_TITLE, NOTE_FIELD, OCCUPANTS_FIELD, RENT_FIELD } from './EditOfferModal.const';
import useOfferDocuments, { useDeleteOfferFile } from 'hooks/query/offers/use-offer-documents';
import { notification } from 'services/services';
import {
  DocumentsVerificationFormValues,
  OfferFile,
} from 'legacy-components/modals/documents-verification-modal/documents-verification-modal.types';

export const EditOfferModal: FC<MakeOfferModalProps> = ({
  availableOn,
  unitId,
  onClose,
  open: openProp,
  onSubmit,
  onGoBack,
  withGoBackButton = false,
  estateId,
  initialValues,
  offerId,
}) => {
  const { isMobile } = useWindowSize();
  const [open, setOpen] = useState(true);
  const [documentsVerificationModalOpen, setDocumentsVerificationModalOpen] = useState(false);
  const [documentsData, setDocumentsData] = useState<DocumentsVerificationFormValues | null>(null);

  const { data: estateUnitPrice, isLoading: isEstateUnitPriceLoading } = useEstateUnitPrice(
    { estateId, unitId },
    { enabled: openProp },
  );
  const { data } = useOfferDocuments(offerId, { enabled: openProp && Boolean(offerId) });
  const { mutateAsync: deleteFileAsync } = useDeleteOfferFile({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'The file has not been deleted');
    },
  });

  const { values, errors, touched, setFieldValue, submitForm, resetForm } = useFormik<MakeOfferValues>({
    initialValues: parseDefaultValues(initialValues),
    validationSchema: makeOfferFormValidationSchema(
      estateUnitPrice?.price || 0,
      estateUnitPrice?.firstMonthPrice || 0,
      estateUnitPrice?.lastMonthPrice || 0,
      estateUnitPrice?.deposit || 0,
      estateUnitPrice?.commissionPercent || 0,
      estateUnitPrice?.otherFeeSum || 0,
      estateUnitPrice?.fixedCommission || 0,
    ),
    onSubmit: () => {
      setOpen(false);
      setDocumentsVerificationModalOpen(true);
    },
  });

  const handleDocumentsVerificationModalClose = () => {
    setOpen(true);
    setDocumentsVerificationModalOpen(false);
  };

  const onDeleteDocument = async (file: File | OfferFile) => {
    if ('id' in file && offerId) await deleteFileAsync({ offerId: offerId || '', fileId: file?.id as string });
  };

  // set initial documents from backend
  useEffect(() => {
    if (data)
      setDocumentsData({
        studentId: null,
        bankStatements: data.map((item) => ({
          id: item?.id,
          name: item?.fileName,
          fileUrl: item?.fileUrl,
        })),
      });
  }, [data]);

  useEffect(() => {
    if (!availableOn) return;
    const date = getMinDate(availableOn);
    setFieldValue('date', date);
  }, [availableOn]);

  // set offer data
  useEffect(() => {
    if (openProp) {
      if (initialValues !== null) resetForm({ values: parseDefaultValues(initialValues) });
      else resetForm({ values: defaultValues });
    }
  }, [openProp, initialValues]);

  const minDate = getMinDate();

  const getTotalOccupants = () => {
    return Number(values.adultOccupants) + Number(values.childrenOccupants);
  }
  
  return (
    <>
      <OfferDocumentsVerificationModal
        onGoBack={() => {
          handleDocumentsVerificationModalClose();
        }}
        onSubmit={async ({ bankStatements }) => {
          handleDocumentsVerificationModalClose();
          resetForm();
          onSubmit?.({ bankStatements, values });
        }}
        onClose={() => {
          handleDocumentsVerificationModalClose();
          onClose();
        }}
        open={documentsVerificationModalOpen}
        onDeleteDocument={onDeleteDocument}
        initialDocumentsProps={documentsData}
      />
      <Modal
        title={MODAL_TITLE}
        isOpen={open && openProp}
        withGoBackButton={withGoBackButton}
        isFullScreen={isMobile}
        onGoBack={onGoBack}
        contentClassName={'w-[715px]'}
        onOpenChange={(open) => {
          if (open) return;
          onClose();
        }}
        footer={
          <div className='flex justify-end'>
            <Button label={BTN_SUBMIT} theme={'primary'} onClick={submitForm} className={'w-full sm:w-auto'} />
          </div>
        }
      >
        {isEstateUnitPriceLoading ? (
          <Loader className={'h-[322px]'} />
        ) : (
          <div className='flex flex-col gap-3'>
            {estateUnitPrice?.price && (
              <div className='px-3 py-4 bg-secondary text-center text-base rounded-xl text-trueGray'>
                <span>{'Ensure that proposed rent is not lower than property list price '}</span>
                <span className='font-bold'>${formatPriceValue(estateUnitPrice?.price || 0)}</span>
              </div>
            )}
            <div className={'grid grid-cols-2 gap-3 sm:gap-6 md:gap-9'}>
              <Label label={DATE_FIELD} className={'col-span-2 sm:col-span-1'}>
                <ErrorValidationMessage touched={touched.date} message={errors.date}>
                  <CustomDatePicker
                    minDate={minDate}
                    name='date'
                    value={values.date}
                    onChange={(date) => setFieldValue('date', date)}
                    invalid={Boolean(errors.date && touched.date)}
                  />
                </ErrorValidationMessage>
              </Label>
              <div className={'hidden sm:block'}></div>
              <Label label={RENT_FIELD} className={'col-span-2 sm:col-span-1'}>
                <ErrorValidationMessage touched={touched.proposedRent} message={errors.proposedRent}>
                  <Input
                    name={'proposedRent'}
                    value={values.proposedRent}
                    onChange={(e) => stringAsIntegerNumber(e, `proposedRent`, setFieldValue)}
                    invalid={Boolean(errors.proposedRent && touched.proposedRent)}
                  />
                </ErrorValidationMessage>
              </Label>
              <div className={'hidden sm:block'}></div>  
              <div className={'col-span-2'}>
                <div className={'grid grid-cols-3 gap-3 sm:gap-6 md:gap-9'}>
                  <Label label={'Total Occupants'} className={'col-span-3 sm:col-span-1'}>
                      <ErrorValidationMessage touched={touched.totalOccupants} message={errors.totalOccupants}>
                        <Input
                          name={'totalOccupants'}
                          disabled
                          value={getTotalOccupants()}
                          invalid={Boolean(errors.totalOccupants && touched.totalOccupants)}
                        />
                      </ErrorValidationMessage>
                    </Label>
                    <Label label={'Adult (18 yrs old +)'} className={'col-span-3 sm:col-span-1'}>
                      <ErrorValidationMessage touched={touched.adultOccupants} message={errors.adultOccupants}>
                        <Input
                          name={'adultOccupants'}
                          value={values.adultOccupants}
                          onChange={(e) => stringAsIntegerNumber(e, `adultOccupants`, setFieldValue)}
                          onBlur={(e) => setFieldValue('totalOccupants', getTotalOccupants())}
                          invalid={Boolean(errors.adultOccupants && touched.adultOccupants)}
                          placeholder='e.g. 3'
                        />
                      </ErrorValidationMessage>
                    </Label>
                    <Label label={'Children (0-18 yrs old)'} className={'col-span-3 sm:col-span-1'}>
                      <ErrorValidationMessage touched={touched.childrenOccupants} message={errors.childrenOccupants}>
                        <Input
                          name={'childrenOccupants'}
                          value={values.childrenOccupants}
                          onChange={(e) => stringAsIntegerNumber(e, `childrenOccupants`, setFieldValue)}
                          onBlur={(e) => setFieldValue('totalOccupants', getTotalOccupants())}
                          invalid={Boolean(errors.childrenOccupants && touched.childrenOccupants)}
                          placeholder='e.g. 3'
                        />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                </div>
                <Label label={NOTE_FIELD} className={'col-span-2'}>
                  <ErrorValidationMessage touched={touched.note} message={errors.note}>
                    <Textarea
                      name={'note'}
                      value={values.note}
                      onChange={(e) => setFieldValue('note', e.target.value)}
                      invalid={Boolean(errors.note && touched.note)}
                    />
                  </ErrorValidationMessage>
                </Label>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
